body {
  display: flex;
  margin: 0;
}

a {
  color: inherit; /* Inherits color from parent element */
}

.section {
  background-image: url('../images/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* overflow: hidden; /* Hide overflow */
  width: 100vw; /* Responsive width */
  height: 100vh; /* Maintain aspect ratio */
  text-align: center;

  .spacer {
    padding-bottom: 20px;
  }

  .container {
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
    border: 1px solid #444; /* Dark gray border */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Space inside the box */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Subtle shadow for depth */
    text-align: center; /* Center text */
    color: white; /* White text color */
    width: fit-content;
    margin: auto;

    .title {
      font-size: 4.5rem;
      text-shadow: 2px 2px 8px rgba(111,66,80,0.4);
      letter-spacing: 4px;
      font-family: Montserrat, sans-serif;
      text-transform: uppercase;
      font-weight: 600;
      width: 861px;
      padding-top: 20px;
      margin: 0px auto 20px;
    }

    .description {
      letter-spacing: 0px;
      line-height: 1.4;
      font-size: 1.5rem;
      font-family: Lato, sans-serif;
      font-weight: 400;
      text-transform: none;
      text-shadow: 2px 2px 8px rgba(0,0,0,0.4);
      width: 714px;
      margin: 0px auto 0;
    }
  }
}


@media (max-width: 1199px) {
  .section .container {
      min-height: 300px;
  }
}

@media (max-width: 991px) {
  .section .container {
    min-height: 2px;

    .title {
      width: 720px;
      font-size: 4rem;
    }

    .description {
      width: 720px;
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 767px) {
  .section .container {
    min-height: 275px;

    .title {
      width: 540px;
      font-size: 3.4375rem;  
    }

    .description {
      width: 540px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 575px) {
  .section .container {
    min-height: 200px;

    .title {
      width: 340px;
      font-size: 2.125rem;
      
    }

    .description {
      width: 340px;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 480px) {
  .section .container {
    min-height: 150px;

    .title {
      width: 240px;
      font-size: 1.725rem;
      
    }

    .description {
      width: 240px;
      font-size: 0.6rem;
    }
  }
}